<script>
/* eslint-disable */
import { mapState, mapActions } from 'vuex';
import momentTZ from 'moment-timezone';

const { days, PREMIUM_PLANS, timezones, rouletteIntervals } = require('@/fixture');
const {
  getWarningMessageData,
  getDefaultInvitationMessage,
  getDefaultGroupMessage,
  WEEKDAYS_OF_WEEK,
} = require('@/helpers/roulette-form-messages');

const defaultTimezone = momentTZ.tz.guess();

export default {
  name: 'Roulette',
  props: {
    roulette: {
      type: Object,
      default: () => ({
        category: 'LUNCH',
        timezone: defaultTimezone,
        isRsvpRequired: true,
        isLocked: false,
        isEditable: true,
        intervalInWeeks: 1,
        days: [],
        invitationMessage: getDefaultInvitationMessage('lunch'),
        groupMessage: getDefaultGroupMessage('lunch'),
        shouldScheduleEvent: false,
      }),
    },
    initialSelectedDays: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      days,
      timezones,
      rouletteIntervals,
      showInvitationMessagePreview: false,
      showGroupMessagePreview: false,
    };
  },
  computed: {
    ...mapState({
      channelLoading: (state) => state.channel.loading,
      channels: (state) => state.channel.channels,
      usersLoading: (state) => state.user.loading,
      users: (state) => state.user.users,
      tier: (state) => state.login.tier,
      roulettes: (state) => state.roulette.roulettes,
      enableAutomaticScheduling: (state) => state.login.enableAutomaticScheduling,
    }),
    stateOfDaysInput() {
      return this.roulette.days && this.roulette.days.length > 0;
    },
    isUpgradeAlertDisplayed() {
      return this.roulettes.some((r) => r.isQuotaExceeded);
    },
    usersAvailableInCurrentPlan() {
      return PREMIUM_PLANS[this.tier] ? PREMIUM_PLANS[this.tier].maxUser : 24;
    },
    optedOutUsers() {
      return this.users.filter(
        (u) => this.roulette.optedOut && this.roulette.optedOut.includes(u._id)
      );
    },
    warningMessage() {
      const selectedDays = this.roulette.id
        ? this.roulette.days.filter((d) => !this.initialSelectedDays.includes(d))
        : this.roulette.days;

      return getWarningMessageData({
        isRsvp: this.roulette.isRsvpRequired,
        selectedDays,
      });
    },
    rouletteBuffer() {
      return this.roulette.isRsvpRequired ? 48 : 24;
    },
  },
  methods: {
    ...mapActions(['deleteRoulette', 'fetchRoulettes', 'optOutFromRoulette']),
    onSubmit() {
      const saveRoulette = () => {
        const rouletteToSave = {
          ...this.roulette,
          invitationMessage: this.roulette.invitationMessage,
          groupMessage: this.roulette.groupMessage,
        };
        this.$emit('save', rouletteToSave);
        this.$bvToast.toast('Your changes have been saved', {
          title: '✓ Success',
          variant: 'success',
          autoHideDelay: 3000,
        });
      };

      if (this.roulette.id) {
        saveRoulette();
        return;
      }

      this.$bvModal
        .msgBoxConfirm(
          'Creating this roulette will send a Slack message to each member of the selected groups on a weekly or monthly basis, connecting them randomly with a new person',
          {
            title: 'Create roulette?',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((confirmed) => {
          if (!confirmed) return;
          saveRoulette();
        });
    },
    updateOptedOut(value) {
      this.roulette.optedOut = value.map((i) => i._id); // eslint-disable-line vue/no-mutating-props
    },
    confirmDelete() {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete.', {
          title: 'Delete roulette?',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (confirmed) => {
          if (!confirmed) return;
          await this.deleteRoulette(this.roulette.id);
          await this.fetchRoulettes();
          if (this.roulettes.length) {
            this.$router.replace({
              name: 'edit',
              params: { id: this.roulettes[0].id },
            });
          } else {
            this.$router.replace({ name: 'home' });
          }
        });
    },
    toggleInvitationMessage() {
      this.showInvitationMessagePreview = !this.showInvitationMessagePreview;
    },
    toggleGroupMessage() {
      this.showGroupMessagePreview = !this.showGroupMessagePreview;
    },
    pluralize(word, count, pluralForm) {
      if (count <= 1) {
        return word;
      }
      return pluralForm || `${word}s`;
    },
    mapPreviewMessage(message, isGroupMessage = true) {
      if (!message) return '';

      const dayToDisplay =
        !!this.roulette.days && this.roulette.days.length > 0
          ? WEEKDAYS_OF_WEEK[this.roulette.days[0]]
          : '';

      let mappedMessage = message
        .replace('_ROULETTE_', this.roulette.name || ' ')
        .replace('_CATEGORY_', this.roulette.category.toLowerCase());

      if (isGroupMessage) {
        if (this.roulette.addRandomMessage) {
          mappedMessage = mappedMessage.replace('_TOPIC_', 'What book changed your life?');
        }
        return mappedMessage;
      }

      return mappedMessage.replace('_NAME_', '@john.doe').replace('_DAY_', dayToDisplay);
    },
    addRandomMessageSwitch() {
      if (this.roulette.addRandomMessage) {
        this.roulette.groupMessage = `${this.roulette.groupMessage}_TOPIC_`;
      } else {
        this.roulette.groupMessage = this.roulette.groupMessage.replaceAll('_TOPIC_', '');
      }
    },
    onGroupMessageBlur() {
      this.roulette.addRandomMessage = this.roulette.groupMessage.includes('_TOPIC_');
    },
  },
};

/* eslint-disable max-len,vue/no-mutating-props */
</script>
<template>
  <div class="p-4">
    <b-alert :show="isUpgradeAlertDisplayed" class="w-75" variant="danger">
      <small>
        <b>
          You have roulettes with more than {{ usersAvailableInCurrentPlan }} users and everyone is
          not covered. We recommend you to upgrade your plan so we can include everyone.
        </b>
      </small>
    </b-alert>
    <b-form @submit.stop.prevent="onSubmit">
      <h3>Configure your roulettes and let it do the rest! 🚀</h3>
      <b-form-group label-for="category" class="w-75 mt-5 d-flex flex-column">
        <div class="category-btn mr-4 d-inline-block">
          <b-button
            variant="outline-primary"
            class="w-100"
            :pressed="roulette.category == 'LUNCH'"
            :disabled="!roulette.isEditable"
            @click="roulette.category = 'LUNCH'"
          >
            <h1>🌮</h1>
            <p>Lunch Roulette</p>
          </b-button>
          <small class="text-muted">Coworkers go out together, for a lunch.</small>
        </div>
        <div class="category-btn d-inline-block">
          <b-button
            variant="outline-primary"
            class="w-100"
            :pressed="roulette.category == 'COFFEE'"
            :disabled="!roulette.isEditable"
            @click="roulette.category = 'COFFEE'"
          >
            <h1>☕️</h1>
            <p>Online Coffee Chat</p>
          </b-button>
          <small class="text-muted">Coworkers socialise online over Slack, Zoom, Teams etc.</small>
        </div>
      </b-form-group>
      <b-form-group label="Name" label-for="roulette-name" class="w-75 mt-5">
        <b-form-input
          id="roulette-name"
          v-model="roulette.name"
          placeholder="Enter your name"
          required
          :disabled="!roulette.isEditable"
        />
        <b-form-text>Name this roulette. Participants will see where they are joining.</b-form-text>
      </b-form-group>
      <b-form-group label="Group size" label-for="group-size" class="w-75 mt-5">
        <b-form-select
          id="group-size"
          v-model="roulette.size"
          :options="[2, 3, 4, 5, 6, 7, 8]"
          required
          :disabled="!roulette.isEditable"
        />
        <b-form-text>
          Number of members for each group. We might adjust the group size to increase participation
          rate.
        </b-form-text>
      </b-form-group>
      <b-form-group
        v-if="roulette.intervalInWeeks"
        label="Roulette Frequency"
        label-for="roulette-frequency"
        class="w-75 mt-5"
      >
        <b-form-select
          id="roulette-frequency"
          v-model="roulette.intervalInWeeks"
          :options="rouletteIntervals"
          required
          :disabled="!roulette.isEditable"
        />
        <b-form-text>
          You can specify the frequency of this roulette, which can range from once a week to once
          every two months.
        </b-form-text>
      </b-form-group>
      <b-form-group label="Day(s)" label-for="days" class="w-75 mt-5">
        <b-form-checkbox-group
          v-model="roulette.days"
          class="w-50"
          :options="days"
          buttons
          button-variant="outline-primary"
          :state="stateOfDaysInput"
          :disabled="!roulette.isEditable"
        />
        <b-form-text>
          You can select multiple days. Consider your team dynamics because we'll be inviting them
          one day in advance.
        </b-form-text>

        <b-alert :show="!!warningMessage.invalidDays.length" class="w-100 mt-3" variant="warning">
          <small>
            Any selected days that have already passed the processing time (<b>{{
              warningMessage.processingTime
            }}</b>
            local time) or are within the next <b>{{ rouletteBuffer }}</b> will be skipped until the
            following interval. <br />Invitations for the following
            {{ pluralize('day', warningMessage.invalidDays.length, 'days') }} will not be sent this
            week.
            <ul>
              <li v-for="day in warningMessage.invalidDays" :key="day">{{ day }}</li>
            </ul>
          </small>
        </b-alert>
      </b-form-group>
      <b-form-group label="Timezone" label-for="timezone" class="w-75 mt-5">
        <b-form-select
          id="timezone"
          v-model="roulette.timezone"
          :options="timezones"
          required
          :disabled="!roulette.isEditable"
        />
        <b-form-text>
          We'll group the participants at 09:30 on the above selected day(s). Please make sure that
          there is at least <b>{{ rouletteBuffer }}</b> hours before the event day for each newly
          created roulette.
          {{
            roulette.isRsvpRequired ? 'That timeframe is needed for RSVP and matching people.' : ''
          }}
        </b-form-text>
      </b-form-group>
      <b-form-group
        label="Get users from this channel"
        label-for="channel-select"
        class="w-75 mt-5"
      >
        <multiselect
          id="channel-select"
          v-model="roulette.channels"
          placeholder
          :loading="channelLoading"
          label="name"
          track-by="id"
          :options="channels"
          :multiple="true"
          :allow-empty="false"
          :disabled="!roulette.isEditable"
        />
        <b-form-text>
          Type the channel name to include the participants. If you want to include all the
          organisation, just use a default channel, e.g. #general.
        </b-form-text>
      </b-form-group>
      <b-form-group
        v-if="users && users.length"
        label="Opted out"
        label-for="opt-out-select"
        class="w-75 mt-5"
      >
        <multiselect
          id="opt-out-select"
          :value="optedOutUsers"
          placeholder
          label="name"
          track-by="id"
          :options="users || []"
          :multiple="true"
          :loading="usersLoading"
          :disabled="!roulette.isEditable"
          @input="updateOptedOut"
        />
        <b-form-text>
          Add people to opt-out from this roulette. Have in mind that participants can opt-out by
          themselves too. If you want to opt-in back an opted-out user, just click the X icon next
          to its name to remove them from the opted-out list.
        </b-form-text>
      </b-form-group>

      <div class="w-75 mt-5">
        <hr />
      </div>
      <b-form-group class="w-75 mt-5">
        <b-form-checkbox
          v-model="roulette.isRsvpRequired"
          name="check-button"
          switch
          size="lg"
          :disabled="!roulette.isEditable"
        >
          Ask for RSVP
        </b-form-checkbox>
        <b-form-text class="mb-4">
          Ask for RSVP one day before the scheduled events. RSVP decreases "no show ups" but is a
          friction in participation. We recommend trying out with and without RSVP to find out what
          works best in your organization.
        </b-form-text>
        <b-form-textarea
          id="roulette-invitation"
          v-model="roulette.invitationMessage"
          rows="3"
          max-rows="6"
          :disabled="!roulette.isEditable || !roulette.isRsvpRequired"
        />
        <b-form-row>
          <b-col cols="12" md="8"
            ><b-form-text
              >Invitation message for your coworkers to join the roulette
              <b-icon-question-circle-fill id="tooltip-invitiation-message" />
            </b-form-text>
            <b-tooltip target="tooltip-invitiation-message" triggers="hover">
              To incorporate the roulette values into your message, please include the following
              tags:<br /><br />
              _NAME_ : Slack user name<br />
              _ROULETTE_ : Roulette name<br />
              _CATEGORY_ : coffee/lunch<br />
              _DAY_ : Selected day(s)<br /><br />
            </b-tooltip>
          </b-col>
          <b-col cols="6" md="4" align="end"
            ><b-form-text
              ><b-button
                size="sm"
                variant="link"
                :disabled="
                  !roulette.isEditable || !roulette.isRsvpRequired || !roulette.invitationMessage
                "
                @click="toggleInvitationMessage"
              >
                {{ !showInvitationMessagePreview ? 'Show preview' : 'Hide preview' }}
              </b-button></b-form-text
            ></b-col
          >
        </b-form-row>
        <b-alert
          :show="
            showInvitationMessagePreview && !!roulette.invitationMessage && roulette.isRsvpRequired
          "
          class="w-100 mt-3"
          variant="info"
        >
          <small>
            {{ mapPreviewMessage(roulette.invitationMessage, false) }}
          </small>
        </b-alert>
      </b-form-group>
      <b-form-group label="Group message" label-for="roulette-group" class="w-75 mt-4">
        <b-form-textarea
          id="roulette-group"
          v-model="roulette.groupMessage"
          rows="3"
          max-rows="6"
          :disabled="!roulette.isEditable"
          @blur.native="onGroupMessageBlur"
        />
        <b-form-row>
          <b-col cols="12" md="9"
            ><b-form-text
              >Message that will be sent to your coworkers on the day of the roulette.
              <b-icon-question-circle-fill id="tooltip-group-message"
            /></b-form-text>
            <b-tooltip target="tooltip-group-message" triggers="hover">
              To incorporate the roulette values into your message, please include the following
              tags:<br /><br />
              _ROULETTE_ : Roulette name<br />
              _CATEGORY_ : coffee/lunch<br />
              _TOPIC_ : random topic<br /><br />
            </b-tooltip>
          </b-col>
          <b-col cols="6" md="3" align="end">
            <b-button
              size="sm"
              variant="link"
              :disabled="!roulette.isEditable || !roulette.groupMessage"
              @click="toggleGroupMessage"
            >
              {{ !showGroupMessagePreview ? 'Show preview' : 'Hide preview' }}
            </b-button>
          </b-col>
          <b-form-checkbox
            v-model="roulette.addRandomMessage"
            name="check-button"
            switch
            size="md"
            :disabled="!roulette.isEditable"
            @change="addRandomMessageSwitch"
            class="mt-2"
          >
            Add random question or topic
          </b-form-checkbox>
          <b-form-text class="mb-2">
            Includes a random topic in your group message to help start the conversation.
          </b-form-text>
        </b-form-row>
        <b-alert
          :show="showGroupMessagePreview && !!roulette.groupMessage"
          class="w-100 mt-3"
          variant="info"
        >
          <small>
            {{ mapPreviewMessage(roulette.groupMessage) }}
          </small>
        </b-alert>
      </b-form-group>

      <div class="w-75 mt-5">
        <hr />
      </div>

      <b-form-group class="w-75 mt-5" v-if="enableAutomaticScheduling">
        <b-form-checkbox
          v-model="roulette.shouldScheduleEvent"
          name="check-button"
          switch
          size="lg"
          :disabled="!roulette.isEditable"
        >
          Create a calendar event
        </b-form-checkbox>
        <b-form-text>
          We'll automatically create a calendar event for the matched group members in the roulette.
        </b-form-text>
      </b-form-group>

      <b-form-group class="w-75 mt-5">
        <b-form-checkbox
          v-model="roulette.isActive"
          name="check-button"
          switch
          size="lg"
          :disabled="!roulette.isEditable"
        >
          Activate
        </b-form-checkbox>
        <b-form-text>
          Please make sure that there is at least <b>{{ rouletteBuffer }}</b> hours before the event
          day for each new created roulette.
          {{
            roulette.isRsvpRequired ? 'That timeframe is needed for RSVP and matching people.' : ''
          }}
        </b-form-text>
      </b-form-group>
      <b-form-group class="w-75 mt-5">
        <b-form-checkbox
          v-model="roulette.isLocked"
          name="check-button"
          switch
          size="lg"
          :value="false"
          :unchecked-value="true"
          :disabled="!roulette.isEditable"
        >
          Members can edit this roulette
        </b-form-checkbox>
        <b-form-text>
          You can let your friends in your organization edit this roulette or prevent them by
          disabling this feature.
        </b-form-text>
      </b-form-group>
      <b-form-group class="mt-5">
        <b-button variant="primary" class="px-4" type="submit" :disabled="!roulette.isEditable">
          Save
        </b-button>
        <br />
        <b-button
          size="sm"
          variant="link"
          class="pl-0 delete mt-5"
          :disabled="!roulette.isEditable"
          @click="confirmDelete"
        >
          Delete this roulette
        </b-button>
      </b-form-group>
    </b-form>
  </div>
</template>

<style lang="scss" scoped>
.delete {
  color: var(--danger);
}

.badge span {
  line-height: 2em;
  margin-right: 1em;
}

.category-btn {
  width: 200px;
}

// in order for multiselect to be over form-controls
.multiselect--active {
  z-index: 2;
}
</style>
